import React from "react";
import Container from "../components/chat-channel/Container";
import Layout from "../components/chat-channel/Layout";
import TitleAndMetaTags from "../components/common/TitleAndHeader";
import { RequestForm } from "../components/form";
import { CookiesPoup } from "./homepage";

export default function TermsAndCondtions() {
  return (
    <>
      <TitleAndMetaTags
        title="Data Privacy Addendum - Workativ"
        description="Learn about Workativ's Data Privacy Addendum, outlining our commitment to protecting user data, ensuring compliance with global privacy regulations, and detailing how we collect, store, and use your information securely."
        keywords={["workativ terms and conditions"]}
        ogTitle="Data Privacy Addendum - Workativ"
        ogDescription="Learn about Workativ's Data Privacy Addendum, outlining our commitment to protecting user data, ensuring compliance with global privacy regulations, and detailing how we collect, store, and use your information securely."
      />
      <Container>
        <Layout logoFor="WORKATIV" product="WORKATIV">
          <section className="terms_condtions">
            <div className="space-explorations">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 space-explorations-content">
                    <h1>DATA PROTECTION ADDENDUM </h1>
                  </div>
                </div>
              </div>
            </div>
            <TermsContent />
            {/* <RequestForm isFooterForm={true} /> */}
          </section>
          {/* <CookiesPoup /> */}
        </Layout>
      </Container>
    </>
  );
}

function TermsContent() {
  return (
    <>
      <section className="statistics-boggle privacy-policy">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 statistics-boggle-content ">
              <p>
                This Data Protection Addendum (<b>"Addendum"</b>), dated 1st
                July 2024 and effective as of the Addendum Effect Date (as
                defined below), forms part of the Terms of Service (
                <b>"Terms"</b>) between (i) Workativ Software Solutions LLC
                ("Workativ Software Solutions LLC") and (ii) &lt;Enter name of
                the other party&gt;, each being a <b>"Party"</b> and together
                the <b>"Parties".</b>
              </p>

              <p>
                The Parties hereby agree that the terms and conditions set out
                below shall be added as an Addendum to the Terms and references
                in this Addendum to the Terms are to the Terms as amended by,
                and including, this Addendum.
              </p>

              <h3>1. Definitions </h3>
              <p>
                <b>1.1</b> In this Addendum, the following terms shall have the
                meanings set out below and cognate terms shall be construed
                accordingly:{" "}
              </p>
              <div className="alpha_letters">
                <ul>
                  <li>
                    <b>(a)"Addendum Effective Date"</b> has the meaning given to
                    it in section 2;
                  </li>
                  <li>
                    <b>(b)"Affiliate"</b> means an entity that owns or controls,
                    is owned or controlled by or is or under common control or
                    ownership with either Client or Workativ Software Solutions
                    LLC (as the context allows), where control is defined as the
                    possession, directly or indirectly, of the power to direct
                    or cause the direction of the management and policies of an
                    entity, whether through ownership of voting securities, by
                    contract or otherwise;
                  </li>
                  <li>
                    <b>(c)"Client Personal Data"</b> means any Personal Data
                    Processed by Workativ Software Solutions LLC (i) on behalf
                    of Client (including for the sake of clarity, any Client
                    Affiliate), or (ii) otherwise Processed by Workativ Software
                    Solutions LLC, in each case pursuant to or in connection
                    with instructions given by Client in writing, consistent
                    with the Terms;
                  </li>
                  <li>
                    <b>(d)"Controller to Processor s"</b> means the Standard
                    Contractual Clauses (processors) for the purposes of Article
                    26(2) of Directive 95/46/EC set out in Decision 2010/87/EC
                    as the same are revised or updated from time to time by the
                    European Commission;
                  </li>
                  <li>
                    <b>(e)"Data Protection Laws" </b>means (i) Directive
                    95/46/EC and, from May 25, 2018, Regulation (EU)
                    2016/679 ("GDPR") together with applicable legislation
                    implementing or supplementing the same or otherwise relating
                    to the processing of Personal Data of natural persons, and
                    (ii) to the extent not included in sub-clause (i), the Data
                    Protection Act 1998 of the United Kingdom, as amended from
                    time to time, and including any substantially similar
                    legislation that replaces the DPA 1998;
                  </li>
                  <li>
                    (<b>f)"Privacy Shield"</b> means the EU-US Privacy Shield
                    Framework; and{" "}
                  </li>
                  <li>
                    <b>(g)"Services"</b> means the services to be supplied by
                    Workativ Software Solutions LLC to Client or Client
                    Affiliates pursuant to the Terms.{" "}
                  </li>
                </ul>
              </div>
              <p>
                <b>1.2</b> The terms{" "}
                <b>
                  "Controller", "Data Subject", "Personal Data", "Personal Data
                  Breach", "Process", "Processor"
                </b>{" "}
                and <b>“Supervisory Authority”</b> have the same meanings as
                described in applicable Data Protection Laws and cognate terms
                shall be construed accordingly.
              </p>
              <p>
                <b>1.3</b>&nbsp;Capitalized terms not otherwise defined in this
                Addendum shall have the meanings ascribed to them in the Terms.
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 statistics-boggle-content ">
              <h3>2. Formation of this Addendum</h3>
              <p>
                This Addendum is deemed agreed by the Parties, and comes into
                effect, on the <b>“Addendum Effective Date”</b>, being the later
                of (i) the date that this Addendum is accepted by Client; and
                (ii) Workativ Software Solutions LLC.
              </p>
              <h3>3. Roles of the Parties</h3>
              <p>
                The Parties acknowledge and agree that with regard to the
                Processing of Client Personal Data, and as more fully described
                in <b>Annex 1</b> hereto, Client acts as a Controller and
                Workativ Software Solutions LLC acts as a Processor (as defined
                in section 5.2.4 below).
              </p>
              <p>
                The Parties expressly agree that Client shall be solely
                responsible for ensuring timely communications to Client’s
                Affiliates or the relevant Controller(s) who receive the
                Services, insofar as such communications may be required or
                useful in light of applicable Data Protection Laws to enable
                Client’s Affiliates or the relevant Controller(s) to comply with
                such Laws.{" "}
              </p>
              <h3>4. Description of Personal Data Processing </h3>
              <p>
                In<b>Annex 1</b> to this Addendum, the Parties have mutually set
                out their understanding of the details of the Processing of the
                Client Personal Data to be Processed by Workativ Software
                Solutions LLC pursuant to this Addendum, as required by Article
                28(3) of the GDPR. Either Party may make reasonable amendments
                to <b>Annex 1</b> by written notice to the other Party and as
                reasonably necessary to meet those requirements.<b>Annex 1</b>
                 does not create any obligation or rights for any Party.{" "}
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 statistics-boggle-content ">
              <h3>5. Data Processing Terms </h3>
              <h6 className="mb-2">5.1 </h6>
              <p>
                Client shall comply with all applicable Data Protection Laws in
                connection with the performance of this Addendum. As between the
                Parties, Client shall be solely responsible for compliance with
                applicable Data Protection Laws regarding the collection of and
                transfer to Workativ Software Solutions LLC of Client Personal
                Data. Client agrees not to provide Workativ Software Solutions
                LLC with any data concerning a natural person’s health, religion
                or any special categories of data as defined in Article 9 of the
                GDPR.{" "}
              </p>
              <h6 className="mb-2">5.2 </h6>
              <p>
                Workativ Software Solutions LLC shall comply with all applicable
                Data Protection Laws in the Processing of Client Personal Data
                and Workativ Software Solutions LLC shall:
              </p>
              <h6 className="mb-2">5.2.1 </h6>
              <p>
                process the Client Personal Data relating to the categories of
                Data Subjects for the purposes of the Terms and for the specific
                purposes in each case as set out in <b>Annex 1</b> to this
                Addendum and otherwise solely on the documented instructions of
                Client, for the purposes of providing the Services and as
                otherwise necessary to perform its obligations under the Terms
                including with regard to transfers of Client Personal Data to a
                third country outside to an international organization; Workativ
                Software Solutions LLC shall immediately inform Client if, in
                Workativ Software Solutions LLC’s opinion, an instruction
                infringes applicable Data Protection Laws;
              </p>
              <h6 className="mb-2">5.2.2 </h6>
              <p>
                ensure that persons authorized to process the Client Personal
                Data have committed themselves to confidentiality or are under
                an appropriate statutory obligation of confidentiality;
              </p>
              <h6 className="mb-2">5.2.3 </h6>
              <p>
                implement and maintain the technical and organizational measures
                set out in the Terms and, taking into account the state of the
                art, the costs of implementation and the nature, scope, context
                and purposes of Processing as well as the risk of varying
                likelihood and severity for the rights and freedoms of natural
                persons, implement any further appropriate technical and
                organizational measures necessary to ensure a level of security
                appropriate to the risk of the Processing of Client Personal
                Data as per following:
              </p>
              <p>
                (a) pseudonymization and encryption of Client Personal Data;
              </p>
              <p>
                (b) ensuring ongoing confidentiality, integrity, availability
                and resilience of processing systems and services that process
                Client Personal Data;
              </p>
              <p>
                (c) restoring availability and access to Client Personal Data in
                a timely manner in the event of a physical or technical
                incident; and
              </p>
              <p>
                (d) regularly testing, assessing and evaluating the
                effectiveness of technical and organizational measures for
                ensuring the security of the processing of the Client Personal
                Data.
              </p>
              <p>
                Any amendment to such agreed measures that is necessitated by
                Client shall be dealt with via an agreed change control process
                between Workativ Software Solutions LLC and Client;
              </p>
              <h6 className="mb-2">5.2.4 </h6>
              <p>
                Client (on behalf of the relevant Controller(s), as applicable),
                hereby expressly and specifically authorizes Workativ Software
                Solutions LLC to engage another Processor to Process the Client
                Personal Data (<b>"Other Processor"</b>), and specifically the
                Other Processors listed in <b>Annex 2</b> hereto, subject to
                Workativ Software Solutions LLC's:
              </p>
              <p>
                (a) notifying Client of any intended changes to its use of Other
                Processors listed in Annex 2 by emailing notice of the intended
                change to Client;
              </p>
              <p>
                (b) including data protection obligations in its contract with
                each Other Processor that are materially the same as those set
                out in this Addendum; and
              </p>
              <p>
                (c) remaining liable to the Client for any failure by each Other
                Processor to fulfill its obligations in relation to the
                Processing of the Client Personal Data.
              </p>
              <p>
                In relation to any notice received under section 5.2.4 a., the
                Client shall have a period of 30 (thirty) days from the date of
                the notice to inform Workativ Software Solutions LLC in writing
                of any reasonable objection to the use of that Other Processor.
                The parties will then, for a period of no more than 30 (thirty)
                days from the date of the Client's objection, work together in
                good faith to attempt to find a commercially reasonable solution
                for the Client which avoids the use of the objected-to Other
                Processor. Where no such solution can be found, either Party may
                (notwithstanding anything to the contrary in the Terms)
                terminate the relevant Services immediately on written notice to
                the other Party, without damages, penalty or indemnification
                whatsoever;
              </p>
              <h6 className="mb-2">5.2.5 </h6>
              <p>
                to the extent legally permissible, promptly notify Client of any
                communication from a Data Subject regarding the Processing of
                Client Personal Data, or any other communication (including from
                a Supervisory Authority) relating to any obligation under the
                applicable Data Protection Laws in respect of the Client
                Personal Data and, taking into account the nature of the
                Processing, assist Client (or the relevant Controller) by
                appropriate technical and organizational measures, insofar as
                this is possible, for the fulfillment of Client’s, Client’s
                Affiliates’ or the relevant Controller(s)’ obligation to respond
                to requests for exercising the data subject's rights laid down
                in Chapter III GDPR; Client agrees to pay Workativ Software
                Solutions LLC for time and for out of pocket expenses incurred
                by Workativ Software Solutions LLC in connection with the
                performance of its obligations under this Section 5.2.5;
              </p>
              <h6 className="mb-2">5.2.6 </h6>
              <p>
                upon Workativ Software Solutions LLC’s becoming aware of a
                Personal Data Breach involving Client Personal Data, notify
                Client without undue delay, of any Personal Data Breach
                involving Client Personal Data, such notice to include all
                information reasonably required by Client (or the relevant
                Controller) to comply with its obligations under the applicable
                Data Protection Laws;
              </p>

              <h6 className="mb-2">5.2.7 </h6>
              <p>
                to the extent required by the applicable Data Protection Laws,
                provide reasonable assistance to Client, Client’s Affiliates’ or
                the relevant Controller(s)’ with its obligations pursuant to
                Articles 32 to 36 of the GDPR taking into account the nature of
                the Processing and information available to Workativ Software
                Solutions LLC; Client agrees to pay Workativ Software Solutions
                LLC for time and for out of pocket expenses incurred by Workativ
                Software Solutions LLC in connection with any assistance
                provided in connection with Articles 35 and 36 of the GDPR;
              </p>
              <h6 className="mb-2">5.2.8 </h6>
              <p>
                cease Processing the Client Personal Data upon the termination
                or expiry of the Terms, and at option of Client, Client’s
                Affiliates or the relevant Controller(s) either return or delete
                (including by ensuring such data is in non-readable format) all
                copies of the Client Personal Data Processed by Workativ
                Software Solutions LLC, unless (and solely to the extent and for
                such period as) Country law requires storage of the Personal
                Data. Notwithstanding the foregoing or anything to the contrary
                contained herein, Workativ Software Solutions LLC may retain
                Personal Data and shall have no obligation to return Personal
                Data to the extent required by applicable laws or regulations
                obligations. Any such Personal Data retained shall remain
                subject to the obligations of confidentiality set forth in the
                Terms; and
              </p>
              <h6 className="mb-2">5.2.9 </h6>
              <p>
                make available to Client all information necessary to
                demonstrate compliance with this Addendum and allow for and
                contribute to audits, including inspections, by Client, or an
                auditor mandated by Client. For the purposes of demonstrating
                compliance with this Addendum under this section 5.2.9, the
                Parties agree that once per year during the term of the Terms,
                Workativ Software Solutions LLC will provide to Client, on
                reasonable notice, responses to cybersecurity and other
                assessments. Client agrees to pay Workativ Software Solutions
                LLC for time and for out of pocket expenses incurred by Workativ
                Software Solutions LLC in connection with assistance provided in
                connection with such audits, responses to cybersecurity and
                other assessments.
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 statistics-boggle-content">
              <h3>6. Transfers </h3>
              <p>
                Workativ Software Solutions LLC is certified to Information
                Security Management as per ISO 27001:2013. Workativ Software
                Solutions LLC shall notify Client in writing without undue delay
                if it can no longer comply with its obligations under the
                Privacy compliance, and, in such a case, Workativ Software
                Solutions LLC will have the option of (i) promptly taking
                reasonable steps to remediate any non-compliance with applicable
                obligations under this Addendum, or (ii) engaging in a good
                faith dialogue with Client to determine a new data transfer
                mechanism to carry out the purposes of the Terms. Workativ
                Software Solutions LLC acts as a Processor with respect to
                Personal Data received pursuant to a data transfer.
              </p>
              <p>
                In the event the Privacy Compliance is invalidated, Client and
                each Client Affiliate (on behalf of the relevant Controller(s),
                as the case may be), if applicable (as "data exporter") and
                Workativ Software Solutions LLC (as "data importer"), with
                effect from the commencement of the relevant transfer, shall
                enter into the Controller to Processor SCCs (mutatis mutandis,
                as the case may be) in respect of any transfer (or onward
                transfer) from Client or Client Affiliate to Workativ Software
                Solutions LLC, where such transfer would otherwise be prohibited
                by applicable Data Protection Laws or by the terms of data
                transfer agreements put in place to address applicable Data
                Protection Laws. Appendix 1 to the Controller to Processor SCCs
                shall be deemed to be prepopulated with the relevant sections
                of Annex 1 to this Addendum and the processing operations are
                deemed to be those described in the Terms. Appendix 2 to the
                Controller to Processor SCCs shall be deemed to be prepopulated
                with the following "Taking into account the state of the art,
                the costs of implementation and the nature, scope, context and
                purposes of processing as well as the risk of varying likelihood
                for the rights and freedoms of natural persons, Workativ
                Software Solutions LLC shall implement appropriate technical and
                organizational measures as set forth in the Addendum."{" "}
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 statistics-boggle-content">
              <h3>7. Precedence</h3>
              <p>
                The provisions of this Addendum are supplemental to the
                provisions of the Terms. In the event of any inconsistency
                between the provisions of this Addendum and the provisions of
                the Terms, the provisions of this Addendum shall prevail.
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 statistics-boggle-content">
              <h3>8. Indemnity </h3>
              <p>
                To the extent permissible by law, Client shall indemnify and
                hold harmless Workativ Software Solutions LLC against all (i)
                losses, (ii) third party claims, (iii) administrative fines and
                (iv) costs and expenses (including, without limitation,
                reasonable legal, investigatory and consultancy fees and
                expenses) reasonably incurred in relation to (i), (ii) or iii),
                suffered by Workativ Software Solutions LLC and that arise from
                any breach by Client of this Addendum or of its obligations
                under applicable Data Protection Laws.
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 statistics-boggle-content">
              <h3>9. Severability </h3>
              <p>
                The Parties agree that, if any section or sub-section of this
                Addendum is held by any court or competent authority to be
                unlawful or unenforceable, it shall not invalidate or render
                unenforceable any other section of this Addendum.
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 statistics-boggle-content">
              <h3>10. Others </h3>
              <p>
                The organization ensures that the contract to process PII
                addresses the organization’s role in providing assistance with
                the customer's obligations.
              </p>
              <p>The Agreement considers following and follows:</p>
              <div className="alpha_letters">
                <ol type="a">
                  <li>Privacy by Design and default</li>
                  <li>Achieving Security of Processing</li>
                  <li>
                    Notification of breaches involving PII to a Supervisory
                    authority
                  </li>
                  <li>
                    Notification of breaches involving PII to Customers and PII
                    Principals
                  </li>
                  <li>Conducting Privacy Impact Assessment</li>
                  <li>
                    Assurance of Assistance by the PII Processors if prior
                    consultations with relevant PII Protection authorities are
                    needed.
                  </li>
                  <li>
                    Workativ Software Solutions LLC shall inform the customer if
                    in its opinion a processing instruction infringes applicable
                    legislation or regulation.
                  </li>

                  <li>
                    The organization does not use PII processed under a contract
                    for the purposes of Marketing and Advertising
                  </li>
                  <li>
                    Coordinate with Clients for helping Audit the systems. The
                    organization provides the customer with the appropriate
                    information so that it can demonstrate compliance with their
                    obligations
                  </li>
                  <li>
                    Workativ Software Solutions LLC shall use AWS and PIPL as
                    sub processors with Security and Privacy requirements full
                    filled.
                  </li>
                  <li>
                    The organization shall comply with all statutory and
                    regulatory requirements, ISO 27001:2013, ISO 27701:2019 and
                    EU GDPR requirements.
                  </li>
                  <li>
                    The Data shall be deleted or de-identified after the
                    processing is complete (This is after the retention period
                    selected is complete).
                  </li>
                  <li>
                    Workativ Software Solutions LLC shall inform 24 hours in
                    advance to clients in case of any legally binding requests
                    for disclosure of PII.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
      ;
    </>
  );
}
